<template>
  <div class="bg-secondary-pages">
    <div class="yellow" />
    <div class="rose" />
    <div class="yellow-sec" />
    <div class="white" />
  </div>
</template>

<script>
export default {
  name: 'SecondaryPagesBackground'
}
</script>

<style lang="scss" scoped>
  .bg-secondary-pages {
    animation: 2s fade;
    overflow: hidden;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FAFAFA;
    filter: blur(64px);
    div {
      position: absolute;
    }
    .yellow {
      z-index: 2;
      left: -10%;
      top: -10%;
      width: 20vw;
      height: 40vh;
      background: linear-gradient(180deg, #EDE494 0%, rgba(255, 189, 152, 0.74) 100%);
      opacity: 0.6;
    }
    .rose {
      z-index: 2;
      width: 50vw;
      height: 20vh;
      background: linear-gradient(180deg, #ED9494 0%, rgba(255, 189, 152, 0.74) 100%);
      opacity: 0.56;
      top: 25%;
      left: 25%;
    }
    .yellow-sec {
      z-index: 2;
      width: 20vw;
      height: 50vh;
      background: linear-gradient(180deg, #FAE3A9 0%, #FFFFFF 100%);
      opacity: 0.55;
      right: 10%;
      top: 5%;
    }
    .white {
      z-index: 1;
      width: 65vw;
      height: 80vh;
      background: #fff;
      bottom: -40%;
      left: 20%;
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>