<template>
  <div class="radio-button-group">
    <label
      v-for="option in options"
      :key="option.id"
      class="mb-s"
      :for="option.id"
    >
      <input
        :id="option.id"
        :value="option.id"
        name="radio-input"
        type="radio"
        @change="$emit('input', option)"
      >
      <span class="checkmark" />
      <div class="info">
        <p v-html="option.name" />
        <p class="fs--small fw--light">
          {{ option.description }}
        </p>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputRadio',
  props: {
    options: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .radio-button-group {
    label {
      cursor: pointer;
      padding-left: 30px;
      position: relative;
      display: flex;
      flex-direction: column;
    }
    input {
      display: none;
    }
    input:checked ~ .checkmark:after {
      width: 10px;
      height: 10px;
    }
    .checkmark {
      position: absolute;
      top: calc(50% - 10px);
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #FF5064;
      &::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        background: #FF5064;
        border-radius: 50%;
        transition: 0.3s;
      }
    }
  }
</style>
