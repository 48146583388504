<template>
  <main-template>
    <secondary-pages-background />
    <div class="p-ordering p-other">
      <div class="container-other">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Оформление заказа
          </h1>
          <div class="divider" />
          <p class="mb-l ta--center">
            Все добавленные продукты находятся здесь
          </p>
          <p class="fs--small fw--light mb-l">
            С услугой «Ключи на месте» Вы минимизируете риски от потери своих ключей! Брелок CLICKTOID имеет уникальный ID номер, информацию для Нашедшего и телефон круглосуточной Службы поддержки.
          </p>
          <div class="wrapper-cart mb-xs">
            <p class="fs--medium fw--bold">
              Выбранные продукты:
            </p>
            <div class="divider" />
            <div class="mb-xs">
              <cart-item
                v-for="item in cart"
                :key="item.id"
                class="mb-m"
                :product="item"
              />
            </div>
          </div>
          <div class="wrapper-contact mb-l">
            <p class="fs--medium fw--bold">
              Контактные данные:
            </p>
            <div class="divider" />
            <div class="form mb-m">
              <input-text v-model="form.email" placeholder="E-mail" required />
              <input-text v-model="form.phone" placeholder="Телефон" required />
              <input-text v-model="form.fullname" class="input-initials" placeholder="ФИО" />
            </div>
            <p class="required fw--light fs--small">
              <span class="red-text">*</span> - Обязательные поля
            </p>
          </div>
          <div class="wrapper-delivery">
            <p class="fs--medium fw--bold">
              Варианты доставки:
            </p>
            <div class="divider" />
            <input-radio-group v-model="delivery" :options="options" />
            <div class="divider" />
            <div class="total-price mb-m">
              <p>
                Итого:
              </p>
              <p class="fs--medium fw--bold">
                <span class="red-text">2500</span>&nbsp;<span class="gray-text">₽</span>
              </p>
            </div>
            <div class="wrapper-submit mb-l">
              <input-checkbox
                v-model="checked"
                class="mb-l"
                name="acception"
              >
                Я принимаю условия &nbsp;
                <router-link class="red-text td--underline" to="/privacy-policy">
                  Соглашения
                </router-link>
              </input-checkbox>
              <app-button
                class="btn-submit"
                title="Сделать заказ"
                description="Оплатить и получить продукт"
                primary
                @click.native="submitOrder"
              >
                <icon-debug class="icon" />
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import MainTemplate from '@/layouts/MainTemplate'
import AppButton from '@/components/common/AppButton'
import SecondaryPagesBackground from '@/components/SecondaryPagesBackground'
import CartItem from '@/components/CartItem'
import InputText from '@/components/input/InputText'
import InputRadioGroup from '@/components/input/InputRadioGroup'
import InputCheckbox from '@/components/input/InputCheckbox'
import IconDebug from '@/assets/img/icons/debug.svg'

export default {
  name: 'Ordering',
  components: {
    MainTemplate,
    AppButton,
    SecondaryPagesBackground,
    CartItem,
    InputText,
    InputRadioGroup,
    InputCheckbox,
    IconDebug
  },
  data () {
    return {
      checked: false,
      delivery: '',
      form: {
        email: '',
        phone: '',
        fullname: ''
      },
      options: [
        {
          id: 1,
          name: 'Самовывоз',
          description: 'Из офиса по адресу: г. Москва, ул Академика Варги, дом 8, к. 1, этаж 7, офис 703. (Бесплатно)'
        },
        {
          id: 2,
          name: 'Доставка курьером <span class="red-text">+ 250</span> <span class="gray-text">₽</span>',
          description: 'По г. Москва'
        },
        {
          id: 3,
          name: 'Доставка почтой России',
          description: 'По России'
        }
      ],
      cart: [
        {
          id: 1,
          title: 'Безопасное путешествие',
          quantity: 1,
          price: 1500
        },
        {
          id: 2,
          title: 'Любимый друг',
          quantity: 2,
          price: 500
        }
      ]
    }
  },
  methods: {
    submitOrder() {
      this.$store.dispatch('setOrderSuccessful', true)
      this.$router.push('/#hero')
    }
  }
}
</script>

<style lang="scss" scoped>
  .p-ordering {
    .wrapper {
      max-width: 640px;
      .form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        .input-initials {
          grid-column: 1 / 3;
        }
      }
      .required {
        color: $gray;
      }
      .total-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .wrapper-submit {
        display: flex;
        justify-content: space-between;
        @include max-w-sm {
          flex-direction: column;
          justify-content: flex-start;
        }
        .btn-submit {
          max-width: 300px;
          .icon {
            path {
              fill: $white;
              stroke: $white;
            }
          }
        }
      }
    }
  }
</style>
