<template>
  <label :for="name">
    <input
      :id="name"
      v-model="checked"
      type="checkbox"
      :value="name"
      @change="onChange"
    >
    <div class="circle">
      <checked-svg class="icon" :class="{ active : checked }" />
    </div>
    <slot />
  </label>
</template>

<script>
import CheckedSvg from '@/assets/img/checked.svg'

export default {
  name: 'InputCheckbox',
  components: {
    CheckedSvg
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      checked: ''
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    position: relative;
    cursor: pointer;
    padding-left: 26px;
    display: flex;
    align-items: center;
  }
  input {
    display: none;
  }
  input + label::before {
    cursor: pointer;
    content: '';
    margin-right: 12px;
    color: #FF5064;
  }
  .circle {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #FF5064;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .icon {
      z-index: -1;
      opacity: 0;
      width: 13px;
      transition: 0.3s;
      transform: scale(.2);
      &.active {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
</style>